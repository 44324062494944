import {defaultEnvironment} from './config'
import merge from 'deepmerge-json';

let customerEnv = {
    ...defaultEnvironment,
    backendUrl: "https://cworldtest.ivf.ch/crm-backend-ci/",

    supportMailAddress:'ivf@pharmakon.software',
    versionCheckURL: "https://cworldtest.ivf.ch/C-World/version.json",

    // Definiert welche Übersetzungsdateien geladen werden
    customerTag: 'ivf',
    // Definiert, ob Backend-Anfragen in der Konsole geloggt werden
    backendDebug: false,
    // Definiert, ob es sich um einen Produktiv-Build handelt
    production: false,

    // Link zum eDetailer
    edetailerLink : '',

    /***************************************************************************
     * MODULÜBERGREIFENDE EINSTELLUNGEN
     **************************************************************************/
    // Definiert, ob und wenn ja, welche Einstellungen vom Benutzer gesetzt werden können
    // 14.11.2018 - Einstellungen (insb. Sprachwechsel) erstmal wieder deaktiviert, da noch nicht alle Templates Übersetzt
    settingsEnabled: true,
    settingsPasswordEnabled: false,
    settingsLanguageEnabled: true,
    // Währung
    defaultCurrency: 'CHF',
    // Definiert, welche Daten für die Regionszuordnung angezeigt werden
    regionAssignmentDatafields: ['regionkey', 'name'],
    // Definiert wieviel Regionslevel (zusätzlich zur Linie) im globalen Regionsfilter angezeigt werden. Standard = 2 (Region & Gebiet)
    globalRegionsfilterLevels: 2,
    // Definiert in welchem Modulen der Regionsfilter angezeigt werden soll
    globalRegionsfilterActiveModules: [
        'dashboards',
        'institutions',
        'people',
        'maps-tour',
        'events',
        'reports',
        'sales',
        // 2019-11-08, PhS(MFe): Im Clearing wäre der Regionsfilter schon nützlich
    ],
    // Definiert, ob Benachrichtigungen im globalHeader angezeigt werden
    showGlobalNotifications: true,
    // Definiert, ob Nebeneinrichtungen in Stammdaten angezeigt werden
    showPersonDataInstitutions: true,
    // Definiert, ob bei Duplikatprüfung bei Neuanlage Personen in andere Einrichtungen verschoben/kopiert werden können
    enableDuplicateCheckPeopleMove: true,
    enableDuplicateCheckPeopleCopy: false,
    // Definiert, ob der Titel in Notizen angezeigt wird
    showInstitutionNotesTitle: true,
    showPersonNotesTitle: true,
    // Definiert, ob Personen und Einrichtungen zusammengeführt werden dürfen
    enableClearingMergeInstitutions: true,
    enableClearingMergePeople: true,
    // Listen im Clearing ausblenden
    // NICHT MEHR FUNKTIONAL muss ins backend verschoben werden frontend.clearingListGridSources
    enableClearingListPeople: false,
    enableClearingListPeopleInstitutions: false,

    /***************************************************************************
     * VALIDIERUNG: TELEFON
     **************************************************************************/
    // Ursprüngliche Regex, zu restriktiv für den Anwender
    // phoneValidationRegex: /^(\+[0-9]{2,3}\s)[0-9]{2}(?:(?<=(\b[0-9]{2}\b))(\s[0-9]{3}|((\s[0-9]{1,3}){0,1}$)))(?:(?=(\s[0-9]{2}))(\s[0-9]{2})|(\s[0-9]{1,2})$)*$/,
    phoneValidationRegex: '^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\/0-9]*$',
    // Telefonnummern gehorchen der Regex, werden aber trotzdem bei Eingabe automatisch in das Format der auskommentierten restriktiveren Regex gebracht.
    autocorrectPhone: false,

    /***************************************************************************
     * AUFTRAGSERFASSUNG
     **************************************************************************/
    // Nur in der Division VM soll die Auftragserfassung angezeigt werden dürfen.
    allowedOrderDivisions: [30],
    // Nur in den Divisionen VM soll Tourneemaster angezeigt werden dürfen.
    allowedTourneemasterDivisions: [30],
    // Kennzeichennummer für Tourneemaster
    tourneemasterCharacteristic: 505,
    // Nur in der Division VK soll der Pricing-Button (in E-Stammdaten) angezeigt werden dürfen.
    ivfAllowedPricingDivisions: [25],
    // Auftragserfassung: Lieferempfänger ausblenden
    showOrderDeliveryAddress: false,
    // Auftragserfassung: Rechnungsadresse ausblenden
    showOrderInvoiceAddress: false,
    // Auftragserfasung: Summe aller Artikel anzeigen
    showOrderSum: true,
    // Welche Spalten bei der Artikelsuche angezeigt wrden sollen (default: 'label')
    // Auf Wunsch von Özlem sollen nur die ersten 7 Zeichen der ERP-Nummer angezeigt werden.
    articleSearchColumns: 'CONCAT(LEFT(erp_number, 7), \'  \', pzn, \' - \', label, \' 1 \', Listentries.list_value)',

    /***************************************************************************
     * KONTAKTE & TERMINPLANUNG
     **************************************************************************/
    // Definiert, ob im Kontaktmodul "Dauer" (default <true>) oder "Datum bis" verwendet werden soll
    contactsUseDuration: false,
    // Definiert, ob bei Kontaktberichten auch "contact-items" (Gesprächsthemen, Muster, Abgabeartikel) aktiv sein sollen
    contactsUseItems: true,
    // Definiert, ob die Zeitfenster beim Kontakt-Modul angezeigt werden sollen. (Sonst nur Datum)
    contactsShowTime: true,
	// Wird je Kontaktart einzeln aktiviert
    contactsShowTimeContact: true,
    contactsShowTimeAppointment: true,
    //Definiert Einstellungen zum Anlegen eines Kontakts in der Terminplanung
    contactsSchedulingType: 'appointment',
    contactsSchedulingMethod: 50,
    contactsSchedulingStartTime: '08:00',
    contactsSchedulingDuration: '01:00',
    contactsSchedulingStartGap: 60, // Minuten
    // Definiert, ob Muster direkt für neu angelegte Personen direkt vergeben werden dürfen
    contactsNoSamplesForNewPeople: false,

    /***************************************************************************
     * BESUCHSPLANUNG v2
     **************************************************************************/
    mapsTourAdviceCompleteKeys: ['7', '8', '9'],
    mapsTourAdviceFailureKeys: ['7'],
    mapsTourAdviceSuccessKeys: ['8', '9'],
    mapsTourAdviceAwaitingResponseKeys: ['2', '4'],
    mapsTourAdviceFaxForbiddenCategories: ['1'],

    /***************************************************************************
     * TAGESBERICHT / SPESENERFASSUNG
     **************************************************************************/
    dailyreportVehicleTypeEnabled: false,
    dailyreportMileageEnabled: false,
    dailyreportMultipleJourneySectionsEnabled: false,
    dailyreportCateringFixedRatesEnabled: false,
    dailyreportVoucherTaxEnabled: false,
    dailyreportExpensesCompositionEnabled: false,
    dailyreportExpenseStatesEnabled: false,
    dailyreportExpenseLockStatusKey: 'closed',
    dailyreportExpenseIncompleteStatusKey: 'incomplete',
    dailyreportExpenseCompleteStatusKey: 'complete',
    dailyreportPrivateDistanceEnabled: true,

    /***************************************************************************
     * GRID
     **************************************************************************/
    institutionsPrependDisplayedColumns: ['select', 'institution-icon'],
    institutionsAppendDisplayedColumns: ['empty', 'person-icon'],
    peoplePrependDisplayedColumns: ['select', 'person-icon'],
    peopleAppendDisplayedColumns: ['empty', 'institution-icon'],
    institutionsFavoriteColumnEnabled: true,
    peopleFavoriteColumnEnabled: true,

    // Selektion - Das Speichern von dynamischen Selektionen erlauben
    allowDynamicSelectionSave: true,

    /***************************************************************************
     * ANSPRECHPARTNER
     **************************************************************************/
    // Definiert, welche Spalten für Einrichtungsansprechpartner angezeigt werden
    institutionsPeopleDisplayedColumns: ['person-icon', 'person-title-name', 'custom-field1', 'custom-field2', 'contact'],

    /***************************************************************************
     * STAMMDATEN (Einrichtungen, Personen, Mitarbeiter)
     **************************************************************************/
    // Definiert, welche Daten in Stammdaten angezeigt werden und Attribute der Eingabefelder
    institutionsDatafields: {
        id:                 {label: 'Einrichtungs-Nr.',     visible: true,  required: true,     disabled: true,     cwInputType: 'text'},
        erp_number:         {label: 'SAP-Nr.',              visible: true,  required: false,                        cwInputType: 'text'}, // erp_number besitzt spezifische Bedingung für disabled
        onekey_number:      {label: 'OneKey-Nummer',        visible: false, required: false,    disabled: true,     cwInputType: 'text'},
        health_insurance_association: {label: 'KV-Name.',   visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        bga_number:         {label: 'BGA-Nummer',           visible: false, required: false,    disabled: true,     cwInputType: 'text'},
        name1:              {label: 'Name',                 visible: true,  required: true,     disabled: false,    cwInputType: 'text'},
        name2:              {label: 'Zusatzbez. 1',         visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        name3:              {label: 'Zusatzbez. 2',         visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        name4:              {label: 'Zusatzbez. 3',         visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        type1:              {label: 'Typ',                  visible: true,  required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'institutionType1'},
        type2:              {label: 'Fachabteilung',        visible: true,  required: false,    disabled: true,     cwInputType: 'select',  cwListentries: 'institutionType2'},
        street:             {label: 'Straße',               visible: true,  required: true,     disabled: false,    cwInputType: 'text'},
        street_number_from: {label: 'Hausnr.',              visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        street_number_to:   {label: 'Hausnr.',              visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        zipcode:            {label: 'PLZ',                  visible: true,  required: true,     disabled: false,    cwInputType: 'text'},
        city:               {label: 'Ort',                  visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        district:           {label: 'Ortsteil',             visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        county:             {label: 'Bundesland',           visible: false, required: false,    disabled: true,     cwInputType: 'select',  cwListentries: 'county'},
        country:            {label: 'Land',                 visible: true,  required: true,     disabled: false,    cwInputType: 'select',  cwListentries: 'country'},
        phone1:             {label: 'Telefon',              visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        phone2:             {label: 'Sonstige Telefonnr.',  visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        phone3:             {label: 'Handy',                visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        fax:                {label: 'Fax',                  visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        mail:               {label: 'E-Mail-Adresse',       visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        internet:           {label: 'Internet-Adresse',     visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        create:             {label: 'Neuanlage',            visible: true}, // create und edit sind nur output und benötigen daher kein required/disabled
        edit: { label: 'Änderung', visible: true },
        supplementary_numbers: { visible: false }
    },
    peopleDatafields: {
        id:                         {label: 'Personennummer',       visible: true,  required: true,     disabled: true,     cwInputType: 'text'},
        erp_number:                 {label: 'SAP-Nr.',              visible: true,  required: false,    disabled: true,     cwInputType: 'text'},
        onekey_number:              {label: 'OneKey-Nummer',        visible: false, required: false,    disabled: true,     cwInputType: 'text'},
        joinData__main_institution: {label: 'Haupteinrichtung',     visible: true,  required: false,    disabled: true,     cwInputType: 'checkbox'},
        lifetime_doctornumber:      {label: 'Lebenslange Arztnummer',     visible: false,  required: false,    disabled: true,     cwInputType: 'text'},
        person_title:               {label: 'Titel',                visible: true,  required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'personTitle'},
        firstname:                  {label: 'Vorname',              visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        lastname:                   {label: 'Nachname',             visible: true,  required: true,     disabled: false,    cwInputType: 'text'},
        joinData__person_function:  {label: 'Funktion',             visible: false, required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'personFunction'},
        person_salutation:          {label: 'Anrede',               visible: true,  required: true,     disabled: false,    cwInputType: 'select',  cwListentries: 'personSalutation'},
        person_salutation_letter:   {label: 'Anrede',               visible: false,  required: false,     disabled: false,    cwInputType: 'select',  cwListentries: 'personSalutationLetter'},
        type1:                      {label: 'Typ',                  visible: false, required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'personType1'},
        person_specialization1:     {label: 'Fachrichtung',         visible: true,  required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'personSpecialization1'},
        person_specialization2:     {label: 'Zusatz-Fachrichtung',  visible: true,  required: false,    disabled: false,    cwInputType: 'select',  cwListentries: 'personSpecialization2'},
        assistant1:                 {label: 'Name der Assistenz',   visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        assistant2:                 {label: 'Name der Assistenz',   visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        assistant3:                 {label: 'Name der Assistenz',   visible: false, required: false,    disabled: false,    cwInputType: 'text'},
        joinData__phone1:           {label: 'Telefon',              visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__phone2:           {label: 'Sonstige Telefonnr.',  visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__phone3:           {label: 'Handy',                visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__fax:              {label: 'Fax',                  visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__mail:             {label: 'E-Mail-Adresse',       visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__mail_type:        {label: 'E-Mail-Adresse Typ',   visible: false, required: false,    disabled: false,    cwInputType: 'select', cwListentries: 'personMailType'},
        joinData__internet:         {label: 'Internet-Adresse',     visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__addressaddon1:    {label: 'Adresszusatz',         visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        joinData__addressfield:     {label: 'Adressfeld',           visible: true,  required: false,    disabled: false,    cwInputType: 'text'},
        create:                     {label: 'Neuanlage',            visible: true}, // create und edit sind nur output und benötigen daher kein required/disabled
        edit:                       {label: 'Änderung',             visible: true}
    },
    // Admin - Users - Employee Inputfelder Definitionen
    adminUsersEmployeeDatafields: {
        time_tracking_id:           {visible: false,    required: false,    disabled: false},
        description:                {visible: false,    required: false,    disabled: false},
        mail:                       {visible: true,     required: false,    disabled: false},
        language_key:               {visible: true,     required: false,    disabled: false},
        costcenter:                 {visible: false,    required: false,    disabled: false},
        account_payable:            {visible: false,    required: false,    disabled: false},
        region_id:                  {visible: true,     required: false,    disabled: false},
        exchangesync:               {visible: true,     required: false,    disabled: false},
        regions:                    {visible: false,    required: false,    disabled: false},
        calendar_id:                {visible: false,    required: false,    disabled: false},
        street:                     {visible: false,    required: false,    disabled: false},
        zipcode:                    {visible: false,    required: false,    disabled: false},
        city:                       {visible: false,    required: false,    disabled: false},
        phone1:                     {visible: false,    required: false,    disabled: false},
        phone2:                     {visible: false,    required: false,    disabled: false},
        phone3:                     {visible: false,    required: false,    disabled: false},
        fax:                        {visible: false,    required: false,    disabled: false},
    },

    // Zwischenlösung zum Anzeigen der OneKey-Spalte
    showOneKeyNumberColumn: false,
    // Zwischenlösung zum Anzeigen der Bga-Spalte
    showBgaNumberColumn: false,
    // Zwischenlösung zum Anzeigen der "Eigener Umsatz"-Spalte (Regions-Spezifischer Umsatz)
    showOwnTurnoverColumn: false,

    /***************************************************************************
     * Diagramme
     **************************************************************************/
    // Standard Farben für Chart Komponente
    chartColors: [
       '#f0a762',
       '#8fbc8f',
       '#7798bf',
    ],
    //welche Chart in Institution Sales angezeigt werden soll auswählbar (products-sales,sales-per-month,sales-per-product)
    chartInstitutionsSales: [
        'products-sales'
    ],

    /***************************************************************************
     *
     **************************************************************************/
    // Zwischenlösung zum Anzeigen der Custom-Sortierung
    showCustomSort: false,
    customSortLabel: '',
    customSortLockedDirection: '',
    customSortInitialDirection: '',

    // Darf bei Admin-Users-Data die PharmaPilot-Checkbox angezeigt werden / Dürfen User für den PharmaPilot freigeschaltet werden.
    allowPharmaPilotUsers: false,

    /***************************************************************************
     * Umsatzanalyse & Produktanalyse
     **************************************************************************/
    // Darf über die Overlay-Info-Komponente in die Produktanalyse gesprungen werden?
    allowSalesAnalysisProductView: false,
    // Soll die Produktanalyse die gleichen Layouts nutzen wie die Umsatzanalyse
    useSalesAnalysisLayoutInProductView: false,

    /***************************************************************************
     * E-Visit & E-Detailer
     **************************************************************************/
    // e-visit
    eVisit: false,
    // Links zum remote edetailer in Personen/Einrichtungstoolbar anzeigen
    showEdetailerLinks: false,

    // Popup für Kennzeichen aktiviert
    characteristicsPopupEnabled: true,

    // Dropdowns für Titel, Anrede, etc. abhängig vom Geschlecht anzeigen
    sexRelatedSelectboxEnabled: false,
    // Dropdowns abhängig vom Personentyp
    personTypeRelatedSelectboxEnabled: false,
    // Dropdowns abhängig vom EinrichtungsBeziehungstyp
    hierarchyRelatedSelectboxEnabled: false,
    // Dropdowns abhängig vom Einrichtungstyp
    institutionTypeRelatedSelectboxEnabled: false,
    orderPaymentConditionDependsOnCharacteristic: false,

    // Definition des Auftragserfassungsfeldes
    orderTrackingDatafields: {
        id:                         {visible: true,  required: true,  disabled: true},
        order_status:               {visible: true,  required: true,  disabled: true, cwListentries: 'orderStatus'},
        // Auftragsart
        order_type:                 {visible: true,  required: true,  disabled: false, cwListentries: 'orderType'},
        // Sortiment
        order_range:                {visible: true,  required: true,  disabled: false, cwListentries: 'orderRange'},
        employee:                   {visible: false, required: false, disabled: true},
        // Achtung Sonderfall für die Zahlungsbedingungen: Falls die orderPaymentConditionDependsOnCharacteristic-Variable "true" ist,
        // wird eine alternative payment_condition-Selectbox angezeigt. Diese kann nur über die entspr. Variable ausgeblendet werden.
        payment_condition:          {visible: false, required: false, disabled: false, cwListentries: 'orderPaymentCondition'},
        order_date:                 {visible: true,  required: true,  disabled: false},
        order_address:              {visible: true,  required: true,  disabled: true},
        delivery_date:              {visible: true,  required: false, disabled: false},
        delivery_address:           {visible: false, required: false, disabled: false},
        delivery_note:              {visible: false, required: false, disabled: false, maxlength: 160},

        delivery_time_from:         {visible: false, required: false, disabled: false},
        delivery_time_to:           {visible: false, required: false, disabled: false},

        // Feld für den Wirklichen Lieferzeitpunkt (Besteht aus Datums und Zeit-Feld, sollte immer disabled sein, da die Info von Außen kommen sollte)
        delivery_time:              {visible: false, required: false, disabled: true},
        // Trackin-ID und URL. Externe Werte deswegen eig. immer disabeld
        tracking_number:            {visible: false, required: false, disabled: true},
        tracking_url:               {visible: false, required: false, disabled: true},
        // Extra Lieferkosten
        shipping_costs:             {visible: false, required: false, disabled: true},

        wholesaler_address:         {visible: true,  required: false, disabled: false},
        value_date:                 {visible: true,  required: false, disabled: false},
        invoice_address:            {visible: false, required: false, disabled: false},
        invoice_note:               {visible: false, required: false, disabled: false, maxlength: 4000},

        // Purchas_order-Nummer
        purchase_order_number:      {visible: false, required: false, disabled: false},

        text:                       {visible: true,  required: false, disabled: false},
        // Gesamtmenge
        order_articlesum:           {visible: true,                   disabled: true},
        // AEP - vor abzug der Artikel-Rabatte
        order_total_before_discount:{visible: false,                  disabled: true},
        // Durschnitt aller Produkt-Rabatte, macht nur Sinn wenn kein Gesamt-Rabatt vergeben wird.
        order_discount_text:        {visible: false,                  disabled: true},
        // Zwischen-Summe
        order_sum:                  {visible: true,                   disabled: true},
        // Gesamt-Rabatt
        order_discount:             {visible: true,  required: false, disabled: false},
        // Gesamt-Summe unter Abzug aller Rabatte
        order_total:                {visible: true,                   disabled: true}
    },

    // Definition der Spalten in der Auftragsitems-Tabelle in der Auftragserfassung
    orderTrackingItemColumns: {
        defaultMode: ['label', 'pzn', 'ean', 'amount', 'unit_price', 'discount', 'total_price'],
        editMode: ['label', 'pzn', 'ean', 'amount', 'unit_price', 'discount', 'total_price', 'deleteColumn']
    },

    allowChangeHierarchy: false,

    reportsContactsListDisplayedColumns: [
        'contact_start',
        'responsible-employee',
        'contact-type',
        'contact-method',
        'contacts-overview-person',
        'contacts-overview-institution',
        'contact-info',
        'empty',
    ],

    // Feld Titel im Kontakt einblenden
    contactsShowContactTitle: true,
    contactTitleRequired: true,
};

export const environment = merge(defaultEnvironment, customerEnv);
